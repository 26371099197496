import env from '@/env'

export interface MenuItemProps {
  title: string
  icon: any
  href?: string
  child?: MenuItemProps[]
  megaMenu?: MenuItemProps[]
  multi_menu?: MenuItemProps[]
  nested?: MenuItemProps[]
  isHeader?: boolean
  onClick: () => void
}

export const menusConfig = [
  {
    title: 'Campaigns',
    href: '/campaigns',
    icon: 'lucide:flame',
    disabled: false,
  },
  {
    title: 'Accounts',
    href: '/accounts',
    icon: 'lucide:building',
    child: [
      {
        title: 'Advertisers',
        href: '/accounts/advertisers',
        icon: 'lucide:building',
      },
      {
        title: 'Publishers',
        href: '/accounts/publishers',
        icon: 'lucide:building',
      },
    ],
    disabled: false,
  },
  {
    title: 'Dashboard',
    href: '/',
    icon: 'lucide:home',
    isHeader: false,
    disabled: true,
  },
  {
    title: 'Users',
    href: '/users',
    icon: 'lucide:users',
    disabled: true,
  },
  {
    title: 'Publisher Pitches',
    href: '/publisher-pitches',
    icon: 'lucide:megaphone',
    disabled: true,
  },
  {
    title: 'Charges',
    href: '/charges',
    icon: 'lucide:credit-card',
    disabled: true,
  },
  {
    title: 'Payouts',
    href: '/payouts',
    icon: 'lucide:badge-dollar-sign',
    disabled: true,
  },
  {
    title: 'Promo Codes',
    href: '/promo-codes',
    icon: 'lucide:binary',
    disabled: true,
  },
  {
    title: 'Vouchers',
    href: '/vouchers',
    icon: 'lucide:receipt',
    disabled: true,
  },
]

export type ClassicNavType = (typeof menusConfig)[number]
